body {
  margin: 0;
  font-family: 'Futura Light BT';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Futura Light BT';
}

@font-face {
  font-family: 'Futura Book';
  src: url('../assets/fonts/Futura Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Book Font';
  src: url('../assets/fonts/Futura Book font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Bold';
  src: url('../assets/fonts/Futura Bold font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Light BT';
  src: url('../assets/fonts/futura light bt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Medium CBT';
  src: url('../assets/fonts/futura medium condensed bt.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura STD';
  src: url('../assets/fonts/Futura Std Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura STDH';
  src: url('../assets/fonts/Futura Std Heavy.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura Medium Italic';
  src: url('../assets/fonts/Futura Medium Italic font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Futura BT';
  src: url('../assets/fonts/Futura XBlk BT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}



